import { message } from "antd";
import types from "../../../types";

export default function createQuestion(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.question.createQuestion.started:
      return Object.assign(
        {},
        { fetching: true, success: false, error: false }
      );

    case types.question.createQuestion.success:
      // This is wrong.. But there are 4 components using this components which has unncessary state changes and that causes so many sideeffects. For not I am triggering messages from here
      // Fix this after the rush is over, refactor all the question creation related components
      message.success("Question created successfully!");
      return Object.assign(
        {},
        { fetching: false, success: true, data: action.data }
      );

    case types.reset.questionCreation:
      return Object.assign({}, { fetching: false, success: false });

    case types.question.createQuestion.failed:
      // This is wrong.. But there are 4 components using this components which has unncessary state changes and that causes so many sideeffects. For not I am triggering messages from here
      // Fix this after the rush is over, refactor all the question creation related components
      message.error("An error occured! Please try again!");
      return Object.assign(
        {},
        {
          fetching: false,
          success: false,
          error: {
            message: action.data.message ? action.data.message : action.data,
          },
        }
      );

    default:
      return state;
  }
}
