import "./App.less";
import ErrorWarning from "../App/common/Alert/ErrorWarning";
import useOnlineStatus from "../hooks/useOnlineStatus";
import AWS from "aws-amplify";
import awsConfigs from "../aws-exports";
import Routes from "./Routes";
import ac from "../redux/actions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CompleteProfile } from "./Onboarding";
import { NewPasswordRequired } from "./Auth";
import { Loader } from "./common";
import { Hub } from "aws-amplify";
import { message } from "antd";
import { OfflineNotice } from "../App/common/OfflineNotice";
// import UserProfileDeactivateNotice from "../App/common/Alert/DeactivateUserProfile.js";

const configs = Object.assign({}, awsConfigs, {
  API: {
    endpoints: [
      {
        endpoint: "https://e61tiu8wk8.execute-api.us-west-2.amazonaws.com/prod",
        name: "skillTest",
        region: "us-west-2",
        custom_header: async () => {
          return {};
        },
      },
    ],
  },
});

AWS.configure(configs);

AWS.configure({
  API: {
    graphql_headers: async () => {
      const session = await AWS.Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
});

function App() {
  const dispatch = useDispatch();

  const onlineStatus = useOnlineStatus();

  const { username: userId } = useSelector(function ({ signin: { data } }) {
    return data ? data : {};
  });

  const { data: userProfile } = useSelector(function ({ user }) {
    return user;
  });

  const { approved, id: companyId } = useSelector(function ({ company }) {
    return company.data ? company.data : {};
  });

  const companyProfileFetching = useSelector(function ({ company }) {
    return company.fetching;
  });

  const userProfileFetching = useSelector(function ({ user }) {
    return user.fetching;
  });

  const jobListFetching = useSelector(function ({ jobList }) {
    return jobList.fetching;
  });

  const jobList = useSelector(function ({ jobList }) {
    return jobList.data ? jobList.data.items : [];
  });

  const jobTitles = useSelector(function ({ jobTitles }) {
    return jobTitles.data ? jobTitles.data : [];
  });

  const newPasswordRequired = useSelector(function ({ signin }) {
    return signin.data ? signin.data.challengeName : undefined;
  });

  const fetchingCandidatesByCompanyId = useSelector(function ({
    getCandidatesByCompanyId,
  }) {
    return getCandidatesByCompanyId.fetching;
  });

  const fetchingSubscriptionPlan = useSelector(function ({ subscriptionPlan }) {
    return subscriptionPlan.fetching;
  });

  const onBoardingSteps = useSelector(function ({ company }) {
    return company.data ? company.data.steps : "";
  });

  const errorMessageUser = useSelector(({ user }) => {
    return user.error ? user.error[0].message : "";
  });

  useEffect(() => {
    if (!onlineStatus) {
      message.error("You are offline");
    }
  }, [onlineStatus]);

  useEffect(() => {
    if (onlineStatus && typeof onlineStatus !== "string") {
      message.success("You are online");
    }
  }, [onlineStatus]);

  useEffect(() => {
    if (onBoardingSteps) {
      Hub.dispatch("stepsChannel", {
        event: "stepsChannel",
        data: {
          step1: JSON.parse(onBoardingSteps).step1,
          step2: JSON.parse(onBoardingSteps).step2,
          step3: JSON.parse(onBoardingSteps).step3,
        },
        message: "Steps count",
      });
    }
  }, [onBoardingSteps]);

  // FIX: Use realtime db quieries
  useEffect(
    function () {
      if (companyId) {
        dispatch(ac.getCandidatesByCompanyId(companyId));
      }
    },
    [dispatch, companyId]
  );

  useEffect(() => {
    dispatch(ac.getCurrentUser());
  }, [dispatch]);

  useEffect(
    function () {
      if (userId && !newPasswordRequired) {
        dispatch(ac.getUserById(userId));
      }
    },
    [userId, dispatch, newPasswordRequired]
  );

  useEffect(
    function () {
      if (userProfile && userProfile.companyId) {
        dispatch(ac.getCompanyProfile(userProfile.companyId));
      }
    },
    [userProfile, dispatch]
  );

  useEffect(
    function () {
      if (companyId) {
        dispatch(ac.listJobTitles(companyId));
      }
    },
    [companyId, dispatch]
  );

  useEffect(
    function () {
      if (companyId) {
        dispatch(ac.getSubscriptionPlanByCompanyId(companyId));
      }
    },
    [companyId, dispatch]
  );

  if (
    companyProfileFetching ||
    userProfileFetching ||
    jobListFetching ||
    fetchingCandidatesByCompanyId ||
    fetchingSubscriptionPlan
  ) {
    return <Loader />;
  }

  if (newPasswordRequired) {
    return <NewPasswordRequired />;
  }

  // if (userId && userProfile?.companyId && !userProfile?.accountActivated) {
  //   return <UserProfileDeactivateNotice />;
  // }

  if (!!userId && (!companyId || approved === null)) {
    if (!onlineStatus) {
      return <OfflineNotice />;
    }
    if (errorMessageUser) {
      return (
        <div>
          <ErrorWarning
            type="warning"
            message={errorMessageUser}
            description="Contact us via support@hellomolly.com if you are facing the same problem again"
            button={true}
            buttonTitle="Click here to Refresh"
            onClick={() => {
              window.location.reload();
            }}
          />
        </div>
      );
    }
    return <CompleteProfile />;
  }

  return (
    <Routes
      companyApproved={approved}
      signedIn={!!userId}
      userId={userId}
      jobList={jobList}
      jobTitles={jobTitles}
    />
  );
}

export default App;
