import classNames from "./Footer.module.scss";
import Item from "./Item";
import { Children, cloneElement, isValidElement } from "react";

type Props = {
  children?: React.ReactNode;
  className?: string;
  as?: "footer" | "section" | "div";
};

const Footer = ({
  children,
  className,
  as: El = "footer",
}: Props): JSX.Element => {
  const childrenArray = Children.toArray(children);

  const firstItems = childrenArray.filter(
    (item, index) => index !== childrenArray.length - 1
  );
  const lastItem = childrenArray[childrenArray.length - 1];

  const alterdItems = Children.map<React.ReactNode, React.ReactNode>(
    firstItems,
    (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          className: child.props.className + " " + classNames.rightPad,
        });
      }
    }
  );
  return (
    <El className={`${classNames.footer} ${className}`}>
      {alterdItems}
      {lastItem}
    </El>
  );
};

Footer.Item = Item;

export default Footer;
