import { generateActionTypes } from "./utils.redux";

const types = {
  user: {
    signup: {
      ...generateActionTypes("user.signup"),
    },
    confirmAuth: {
      ...generateActionTypes("user.confirmSignup"),
    },
    signin: {
      ...generateActionTypes("user.signin"),
    },
    completeUserProfile: {
      ...generateActionTypes("user.completeProfile"),
    },
    fetchUserProfileById: {
      ...generateActionTypes("user.fetchById"),
    },
    updateProfileCompletionCognito: {
      ...generateActionTypes("user.profileCompletionCognito"),
    },
    inviteMember: {
      ...generateActionTypes("user.inviteMember"),
    },
    resetMemberPassword: {
      ...generateActionTypes("user.resetMemberPassword"),
    },
    updateUserAvatar: {
      ...generateActionTypes("user.updateAvatar"),
    },
    globalSignOut: {
      ...generateActionTypes("user.globalSignOut"),
    },
    deactivateUserProfile: {
      ...generateActionTypes("user.deactivateUserProfile"),
    },
  },
  company: {
    fetchCompanyProfile: {
      ...generateActionTypes("company.fetchCompanyProfile"),
    },
    updateCompanyProfile: {
      ...generateActionTypes("company.updateCompanyProfile"),
    },
    updateCompanyHandler: {
      ...generateActionTypes("company.updateCompanyHandler"),
    },
    updateLogo: {
      ...generateActionTypes("company.logo"),
    },
    getCompanyLogo: {
      ...generateActionTypes("company.getCompanyLogo"),
    },
  },
  jobs: {
    createJob: {
      ...generateActionTypes("jobs.create"),
    },
    list: {
      ...generateActionTypes("jobs.list"),
    },
    get: {
      ...generateActionTypes("jobs.get"),
    },
    listTitles: {
      ...generateActionTypes("jobTitles.list"),
    },
    updateJob: {
      ...generateActionTypes("jobs.update"),
    },
  },
  candidates: {
    list: {
      ...generateActionTypes("candidates.list"),
    },
    updateStage: {
      ...generateActionTypes("candidate.updateStage"),
    },
    resetUpdateStage: {
      ...generateActionTypes("candidate.resetUpdateStage"),
    },
    changeManagerInCharge: {
      ...generateActionTypes("candidate.changeManagerInCharge"),
    },
    scheduleInterview: {
      ...generateActionTypes("candidate.scheduleInterview"),
    },
    listInterviews: {
      ...generateActionTypes("candidate.listInterviews"),
    },
    listByCompanyId: {
      ...generateActionTypes("candidates.listByCompanyId"),
    },

    listInterviewsByCompanyId: {
      ...generateActionTypes("interviews.listByCompanyId"),
    },
    candidatesByCompanyId: {
      ...generateActionTypes("candidates.candidatesByCompanyId"),
    },
    recordSentSkillTests: {
      ...generateActionTypes("candidate.recordSentSkillTests"),
    },
  },
  feedback: {
    createFeedback: {
      ...generateActionTypes("feedback.create"),
    },
    updateFeedback: {
      ...generateActionTypes("feedback.update"),
    },
    deleteFeedback: {
      ...generateActionTypes("feedback.delete"),
    },
    getFeedbackByCandidate: {
      ...generateActionTypes("feedback.getFeedbackByCandidate"),
    },
  },
  messaging: {
    send: {
      ...generateActionTypes("messaging.send"),
    },
    list: {
      ...generateActionTypes("messaging.list"),
    },
    stageChanged: {
      ...generateActionTypes("messaging.stateChanged"),
    },
  },
  userManagement: {
    resetPassword: {
      ...generateActionTypes("userManagement.resetPassword"),
    },
  },
  subscriptionPlan: {
    get: {
      ...generateActionTypes("subscriptionPlan.get"),
    },
  },
  common: {
    completeOnBoardingSteps: {
      ...generateActionTypes("completeOnBoarding.get"),
    },
  },
  skillTest: {
    submitAnswers: {
      ...generateActionTypes("skillTest.submitAnswers"),
    },
    getById: {
      ...generateActionTypes("skillTests.getById"),
    },
    create: {
      ...generateActionTypes("skillTest.create"),
    },
    listByCompanyId: {
      ...generateActionTypes("skillTest.listByCompanyId"),
    },
    assignDefaultSkillTest: {
      ...generateActionTypes("skillTest.assignDefaultSkillTestToAJob"),
    },
    updateById: {
      ...generateActionTypes("skillTest.updateById"),
    },
    getTestTemplates: {
      ...generateActionTypes("skillTest.templates"),
    },
    getTempSkillTestData: {
      ...generateActionTypes("skillTest.getTempData"),
    },
  },
  tests: {
    listByCompanyId: {
      ...generateActionTypes("skillTest.listByCompanyId"),
    },
  },
  tempAnswers: {
    mapAnswersWithQuestion: {
      ...generateActionTypes("tempAnswers.mapWithQuestion"),
    },
  },
  question: {
    getQuestionById: {
      ...generateActionTypes("question.getQuestionById"),
    },
    listQuestions: {
      ...generateActionTypes("question.listQuestions"),
    },
    createQuestion: {
      ...generateActionTypes("question.createQuestion"),
    },
    listQuestionsById: {
      ...generateActionTypes("question.listByIds"),
    },
    listQuestionsByIds: {
      ...generateActionTypes("question.listQuestionsByIds"),
    },
  },
  candidateTest: {
    getById: {
      ...generateActionTypes("candidateTest.get"),
    },
    start: {
      ...generateActionTypes("candidateTest.start"),
    },
    submit: {
      ...generateActionTypes("candidateTest.submit"),
    },
    create: {
      ...generateActionTypes("candidateTest.create"),
    },
    list: {
      ...generateActionTypes("candidateTest.list"),
    },
    updateResult: {
      ...generateActionTypes("candidateTest.UpdateResult"),
    },
    rateResult: {
      ...generateActionTypes("candidateTest.rateResult"),
    },
    sendManagerEmail: {
      ...generateActionTypes("candidateTest.sendManagerEmail"),
    },
  },
  answer: {
    getAllAnswers: {
      ...generateActionTypes("answer.getAllAnswers"),
    },
    createAnswer: {
      ...generateActionTypes("answer.createAnswer"),
    },
  },
  difficulty: {
    listDifficulty: {
      ...generateActionTypes("difficulty.listDifficulty"),
    },
  },
  industry: {
    getAllIndustry: {
      ...generateActionTypes("industry.getAllIndustry"),
    },
  },
  testDuration: {
    getAllTestDuration: {
      ...generateActionTypes("typeDuration.getAllTestDuration"),
    },
  },
  tempQuestions: {
    addQuestionsToTest: {
      ...generateActionTypes("tempQuestions.addQuestionsToTest"),
    },
    removeQuestionsFromTest: {
      ...generateActionTypes("tempQuestions.removeQuestionsFromTest"),
    },
    cleanupTempQuestionsInTest: {
      ...generateActionTypes("tempQuestions.cleanupQuestionsInTest"),
    },
  },
  designation: {
    listDesignation: {
      ...generateActionTypes("designation.listDesignation"),
    },
  },
  questionTypes: {
    listQuestionTypes: {
      ...generateActionTypes("questionTypes.listQuestionTypes"),
    },
  },
  testCompleted: {
    status: "Test has already been  completed + status",
    duration: "Test has been already completed + duration",
  },
  reset: {
    questionCreation: "reset.create.question",
    sendMessage: "reset.sendMessage.send",
    updateCandidateTestResult: "reset.updateCandidateTestResult",
    updateCompanyprofile: "reset.settings.updateCompanyProfile",
    inviteMember: "reset.settings.inviteMember",
    createFeedback: "reset.feedback.create",
    updateFeedback: "reset.feedback.update",
    assignDefaultSkillTest: "reset.skillTestDefaultJob.assign",
    createCandidateTest: "reset.candidateTest.create",
    rateCandidateTest: "reset.candidateTest.rate",
    changeManagerInCharge: "reset.candidate.changeManagerInCharge",
    resetGetJob: "reset.job.getJob",
    resetScheduleInterview: "reset.candidate.scheduleInterview",
  },
};

export default types;
