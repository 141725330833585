import classNames from "./NewPasswordRequired.module.scss";
import mollyAvatar from "../../../images/Signin/molly-avatar.svg";
import mollyText from "../../../images/Signin/molly-text.svg";
import { actionCreators as ac } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { Form, Spin, Alert } from "antd";
import { Title, PrimaryButton, Input } from "../../common";
import { useState } from "react";
import Drift from "react-driftjs";

/**
 * Function representing ChageTempUserPassword component
 * @param {Object} props Component props
 */
function ChangeTempUserPassword(props) {
  const dispatch = useDispatch();
  const [confirmDirty, setConfirmDirty] = useState(false);

  const username = useSelector(({ signin }) => {
    if (signin.data && signin.data.challengeParam) {
      return signin.data.challengeParam.userAttributes.email;
    }
    return null;
  });

  const user = useSelector(function ({ signin }) {
    return signin.data;
  });

  const fetching = useSelector(function ({
    resetMemberPassword: { fetching },
  }) {
    return fetching;
  });

  const { message: errorMessage } = useSelector(function ({
    resetMemberPassword: { error },
  }) {
    return error ? error : {};
  });

  /**
   * Handles password confirmation blur event.
   */
  const handleConfirmBlur = (event) =>
    setConfirmDirty(confirmDirty || !!event.target.value);

  /**
   * Handles form submition
   * @param {Object} event Event triggered by form submition
   */
  function handleSubmit({ currentPassword, newPassword }) {
    dispatch(ac.resetMemberPassword(user, { currentPassword, newPassword }));
  }

  if (!username) {
    window.location.replace("/signin");
  }

  // if (changePasswordSuccess) {
  //   window.location.reload();
  // }

  return (
    <Spin spinning={false}>
      <div className={classNames.wrapper}>
        <img
          src={mollyAvatar}
          alt="Molly logo agatar"
          className={classNames.logo}
        />

        <Title as="h1" style={{ fontSize: "36px" }}>
          Change temporary password
        </Title>

        <div style={{ marginBottom: 10, width: 400 }}>
          {errorMessage && <Alert type="error" message={errorMessage} />}
        </div>

        <Form
          onFinish={handleSubmit}
          layout="vertical"
          className={classNames.tempPassForm}
          hideRequiredMark={true}
        >
          {/* Password field */}
          <Form.Item
            label={
              <Title as="h5" className={classNames.label}>
                Temporary Password <sup style={{ top: 1 }}>*</sup>
              </Title>
            }
            name="currentPassword"
            validateFirst={true}
            rules={[
              { required: true, message: "Temporary password is required" },

              {
                min: 8,
                message: "Must have at least 8 characters",
              },
              // {
              //   pattern: new RegExp(
              //     "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?\"!@#%&/,><':;|_~`^\\]\\[\\)\\(]).{6,}"
              //   ),
              //   message: "Insecure password",
              // },
            ]}
          >
            <Input
              placeholder=""
              className={classNames.input}
              type="password"
            />
          </Form.Item>

          {/* Password field */}
          <Form.Item
            label={
              <Title as="h5" className={classNames.label}>
                New Password <sup style={{ top: 1 }}>*</sup>
              </Title>
            }
            name="newPassword"
            validateFirst={true}
            rules={[
              { required: true, message: "New password is required" },
              {
                min: 8,
                message: "Must have at least 8 characters",
              },
              {
                pattern: new RegExp(
                  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?\"!@#%&/,><':;|_~`^\\]\\[\\)\\(]).{6,}"
                ),
                message: "Insecure password",
              },
            ]}
          >
            <Input
              className={classNames.input}
              placeholder=""
              type="password"
            />
          </Form.Item>

          {/* Confirm password field */}
          <Form.Item
            label={
              <Title as="h5" className={classNames.label}>
                Confirm Password <sup style={{ top: 1 }}>*</sup>
              </Title>
            }
            name="confirmPassword"
            validateFirst={true}
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Password confirmation is required" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Password confirmation does not match");
                },
              }),
            ]}
          >
            <Input
              placeholder=""
              className={classNames.input}
              onBlur={handleConfirmBlur}
              type="password"
            />
          </Form.Item>
          <PrimaryButton
            htmlType="submit"
            type="primary"
            loading={fetching}
            style={{ width: "100%" }}
          >
            Change password
          </PrimaryButton>
        </Form>
        <img
          src={mollyText}
          alt="Molly logo avatar"
          className={classNames.mollyLogoText}
        />
      </div>
      <Drift appId="txw66twatn49" />
    </Spin>
  );
}

export default ChangeTempUserPassword;
