import types from "../../types";
import API from "../../../api";
import signin from "../signin.ac";
import { generateSyncAction } from "../../utils.redux";

export default function resetMemberPassword(user, input) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.user.resetMemberPassword.started));

    try {
      //make another api call (API.user.signin) to get Cognito User Object, to pass resetMemberPassword
      //ref: https://github.com/aws-amplify/amplify-js/issues/1715#issuecomment-728895289
      const signedUser = await API.user.signin(
        user.challengeParam.userAttributes.email,
        input.currentPassword
      );
      const data = await API.user.resetMemberPassword(signedUser, input);
      dispatch(
        signin(user.challengeParam.userAttributes.email, input.newPassword)
      );

      dispatch(
        generateSyncAction(types.user.resetMemberPassword.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.user.resetMemberPassword.failed, error)
      );
    }
  };
}
