/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:eb0df860-da6a-4ae7-a1b1-5a2bad9a4d71",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_jG8wYJ9wC",
    "aws_user_pools_web_client_id": "4pfm0bdhekl0ae5vrgbfc2cdfn",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://lsnshceaxbbpddoqqa2tjawyeq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "molly-file-storage150301-demo",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
