import { GrClose } from "react-icons/gr";

export default function CloseButton({ onClick, history, style }) {
  function handleClick() {
    onClick();

    if (history) {
      history.goBack();
    }
  }
  return (
    <GrClose
      onClick={handleClick}
      style={{
        borderRadius: "50%",
        boxSizing: "content-box",
        padding: 10,
        background: "#e2e2e2",
        cursor: "pointer",
        marginRight: 20,

        ...style,
      }}
    />
  );
}
