import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function createOnboardingProfiles(inputs) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.user.completeUserProfile.started));

    try {
      const data = await API.user.createOnboardingProfiles(inputs);
      dispatch(
        generateSyncAction(types.user.completeUserProfile.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.user.completeUserProfile.failed, error)
      );
    }
  };
}
