import types from "../../types";

export default function signin(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.user.signin.started:
      return Object.assign({}, { fetching: true });

    case types.user.signin.success:
      return Object.assign({}, { fetching: false, data: { ...action.data } });

    case types.user.signin.failed:
      return Object.assign({}, { fetching: false, error: { ...action.data } });

    default:
      return state;
  }
}
