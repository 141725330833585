/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listJobTitles = /* GraphQL */ `
  query ListJobTitles($companyId: ID!) {
    listJobTitles(companyId: $companyId) {
      id
      title
      companyId
      createdAt
    }
  }
`;
export const checkHandlerAvailability = /* GraphQL */ `
  query CheckHandlerAvailability($handler: String!) {
    checkHandlerAvailability(handler: $handler) {
      isAvailable
      handler
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      handlers
      email
      role
      companyId
      accountActivated
      company {
        id
        ownerId
        companyName
        companyEmail
        currentStage
        website
        size
        companyHandler
        industry
        address
        country
        approved
        active
        deleted
        phone
        handlers
        members {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          avatar
          createdAt
          updatedAt
        }
        steps
        logo
        createdAt
        updatedAt
      }
      interviews {
        items {
          id
          candidateId
          managerId
          companyId
          interviewStartTime
          interviewFinishTime
          name
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      avatar
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        avatar
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      ownerId
      companyName
      companyEmail
      currentStage
      website
      size
      companyHandler
      industry
      address
      country
      approved
      active
      deleted
      phone
      handlers
      members {
        items {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          avatar
          createdAt
          updatedAt
        }
        nextToken
      }
      owner {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        avatar
        createdAt
        updatedAt
      }
      steps
      logo
      createdAt
      updatedAt
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        companyName
        companyEmail
        currentStage
        website
        size
        companyHandler
        industry
        address
        country
        approved
        active
        deleted
        phone
        handlers
        members {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          avatar
          createdAt
          updatedAt
        }
        steps
        logo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      experience
      jobRegion
      questions
      currency
      stages
      managerInCharge
      country
      isRemoteJob
      deleted
      minSalaray
      jobType
      maxSalary
      handlers
      description
      title
      isSalaryNegotiable
      companyId
      defaultSkillTestId
      companyHandler
      company {
        id
        ownerId
        companyName
        companyEmail
        currentStage
        website
        size
        companyHandler
        industry
        address
        country
        approved
        active
        deleted
        phone
        handlers
        members {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          avatar
          createdAt
          updatedAt
        }
        steps
        logo
        createdAt
        updatedAt
      }
      candidates {
        items {
          id
          companyId
          jobId
          managerInCharge
          stage
          mobile
          cv
          handlers
          lastName
          firstName
          answers
          email
          candidateTests
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        experience
        jobRegion
        questions
        currency
        stages
        managerInCharge
        country
        isRemoteJob
        deleted
        minSalaray
        jobType
        maxSalary
        handlers
        description
        title
        isSalaryNegotiable
        companyId
        defaultSkillTestId
        companyHandler
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        candidates {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCandidate = /* GraphQL */ `
  query GetCandidate($id: ID!) {
    getCandidate(id: $id) {
      id
      companyId
      jobId
      managerInCharge
      stage
      mobile
      cv
      handlers
      lastName
      firstName
      answers
      email
      candidateTests
      messages {
        items {
          id
          message
          to
          from
          subject
          senderId
          candidateId
          employeeId
          recipientId
          html
          createdAt
          updatedAt
        }
        nextToken
      }
      feeedback {
        items {
          id
          feedback
          employeeId
          candidateId
          rate
          stageId
          createdAt
          updatedAt
        }
        nextToken
      }
      interview {
        items {
          id
          candidateId
          managerId
          companyId
          interviewStartTime
          interviewFinishTime
          name
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCandidates = /* GraphQL */ `
  query ListCandidates(
    $filter: ModelCandidateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      feedback
      employeeId
      candidateId
      rate
      stageId
      candidate {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        feedback
        employeeId
        candidateId
        rate
        stageId
        candidate {
          id
          companyId
          jobId
          managerInCharge
          stage
          mobile
          cv
          handlers
          lastName
          firstName
          answers
          email
          candidateTests
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      message
      to
      from
      subject
      senderId
      candidateId
      employeeId
      recipientId
      html
      candidate {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      member {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        avatar
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        to
        from
        subject
        senderId
        candidateId
        employeeId
        recipientId
        html
        candidate {
          id
          companyId
          jobId
          managerInCharge
          stage
          mobile
          cv
          handlers
          lastName
          firstName
          answers
          email
          candidateTests
          createdAt
          updatedAt
        }
        member {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          avatar
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInterview = /* GraphQL */ `
  query GetInterview($id: ID!) {
    getInterview(id: $id) {
      id
      candidateId
      managerId
      companyId
      interviewStartTime
      interviewFinishTime
      name
      notes
      candidate {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      manager {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        avatar
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInterviews = /* GraphQL */ `
  query ListInterviews(
    $filter: ModelInterviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        candidateId
        managerId
        companyId
        interviewStartTime
        interviewFinishTime
        name
        notes
        candidate {
          id
          companyId
          jobId
          managerInCharge
          stage
          mobile
          cv
          handlers
          lastName
          firstName
          answers
          email
          candidateTests
          createdAt
          updatedAt
        }
        manager {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          avatar
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubscriptionPlan = /* GraphQL */ `
  query GetSubscriptionPlan($id: ID!) {
    getSubscriptionPlan(id: $id) {
      id
      cancel_url
      checkout_id
      validity
      email
      event_time
      next_bill_date
      cancellation_effective_date
      subscription_plan_id
      update_url
      subscription_id
      trial
      companyId
      subscriptionStatus
      company {
        id
        ownerId
        companyName
        companyEmail
        currentStage
        website
        size
        companyHandler
        industry
        address
        country
        approved
        active
        deleted
        phone
        handlers
        members {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          avatar
          createdAt
          updatedAt
        }
        steps
        logo
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSubscriptionPlans = /* GraphQL */ `
  query ListSubscriptionPlans(
    $filter: ModelSubscriptionPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cancel_url
        checkout_id
        validity
        email
        event_time
        next_bill_date
        cancellation_effective_date
        subscription_plan_id
        update_url
        subscription_id
        trial
        companyId
        subscriptionStatus
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        avatar
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMembersByCompanyId = /* GraphQL */ `
  query GetMembersByCompanyId(
    $companyId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMembersByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        avatar
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getByCompanyHandler = /* GraphQL */ `
  query GetByCompanyHandler(
    $companyHandler: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getByCompanyHandler(
      companyHandler: $companyHandler
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        companyName
        companyEmail
        currentStage
        website
        size
        companyHandler
        industry
        address
        country
        approved
        active
        deleted
        phone
        handlers
        members {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          avatar
          createdAt
          updatedAt
        }
        steps
        logo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobsByCompanyId = /* GraphQL */ `
  query JobsByCompanyId(
    $companyId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        experience
        jobRegion
        questions
        currency
        stages
        managerInCharge
        country
        isRemoteJob
        deleted
        minSalaray
        jobType
        maxSalary
        handlers
        description
        title
        isSalaryNegotiable
        companyId
        defaultSkillTestId
        companyHandler
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        candidates {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobsByCompanyHandler = /* GraphQL */ `
  query JobsByCompanyHandler(
    $companyHandler: String
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByCompanyHandler(
      companyHandler: $companyHandler
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        experience
        jobRegion
        questions
        currency
        stages
        managerInCharge
        country
        isRemoteJob
        deleted
        minSalaray
        jobType
        maxSalary
        handlers
        description
        title
        isSalaryNegotiable
        companyId
        defaultSkillTestId
        companyHandler
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        candidates {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCandidatesByJobId = /* GraphQL */ `
  query GetCandidatesByJobId(
    $jobId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCandidateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCandidatesByJobId(
      jobId: $jobId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCandidatesByCompanyId = /* GraphQL */ `
  query GetCandidatesByCompanyId(
    $companyId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCandidateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCandidatesByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedbackByCandidateId = /* GraphQL */ `
  query GetFeedbackByCandidateId(
    $candidateId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFeedbackByCandidateId(
      candidateId: $candidateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        feedback
        employeeId
        candidateId
        rate
        stageId
        candidate {
          id
          companyId
          jobId
          managerInCharge
          stage
          mobile
          cv
          handlers
          lastName
          firstName
          answers
          email
          candidateTests
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMessagesByCandidateId = /* GraphQL */ `
  query ListMessagesByCandidateId(
    $candidateId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagesByCandidateId(
      candidateId: $candidateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        to
        from
        subject
        senderId
        candidateId
        employeeId
        recipientId
        html
        candidate {
          id
          companyId
          jobId
          managerInCharge
          stage
          mobile
          cv
          handlers
          lastName
          firstName
          answers
          email
          candidateTests
          createdAt
          updatedAt
        }
        member {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          avatar
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInterviewsByCandidateId = /* GraphQL */ `
  query ListInterviewsByCandidateId(
    $candidateId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterviewsByCandidateId(
      candidateId: $candidateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        candidateId
        managerId
        companyId
        interviewStartTime
        interviewFinishTime
        name
        notes
        candidate {
          id
          companyId
          jobId
          managerInCharge
          stage
          mobile
          cv
          handlers
          lastName
          firstName
          answers
          email
          candidateTests
          createdAt
          updatedAt
        }
        manager {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          avatar
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInterviewsByManagerID = /* GraphQL */ `
  query ListInterviewsByManagerID(
    $managerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterviewsByManagerID(
      managerId: $managerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        candidateId
        managerId
        companyId
        interviewStartTime
        interviewFinishTime
        name
        notes
        candidate {
          id
          companyId
          jobId
          managerInCharge
          stage
          mobile
          cv
          handlers
          lastName
          firstName
          answers
          email
          candidateTests
          createdAt
          updatedAt
        }
        manager {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          avatar
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInterviewsByCompanyId = /* GraphQL */ `
  query ListInterviewsByCompanyId(
    $companyId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterviewsByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        candidateId
        managerId
        companyId
        interviewStartTime
        interviewFinishTime
        name
        notes
        candidate {
          id
          companyId
          jobId
          managerInCharge
          stage
          mobile
          cv
          handlers
          lastName
          firstName
          answers
          email
          candidateTests
          createdAt
          updatedAt
        }
        manager {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          avatar
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubscriptionByCompanyId = /* GraphQL */ `
  query GetSubscriptionByCompanyId(
    $companyId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubscriptionByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cancel_url
        checkout_id
        validity
        email
        event_time
        next_bill_date
        cancellation_effective_date
        subscription_plan_id
        update_url
        subscription_id
        trial
        companyId
        subscriptionStatus
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
