import mollyText from "../../../assets/molly-text.svg";
import mollyAvatar from "../../../assets/molly-avatar.svg";
import classNames from "./ForgotPassword.module.scss";
import { Auth } from "aws-amplify";
import { Alert, Form, message, Spin } from "antd";
import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import { Title, Input, PrimaryButton, AuthLinkWrapper } from "../../common";
import { validation } from "./config";

function ForgotPassword() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [emailSubmit, setEmailSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(true);

  const handleOnFinish = async ({ email }) => {
    setSubmitting(true);
    try {
      await Auth.forgotPassword(email);
      setEmailSubmit(true);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      console.log(error);
      message.warn(error.message);
    }
  };

  const resetPassword = async ({ code, email, password }) => {
    setSubmitting(true);
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      setSubmitting(false);
      form.resetFields();
      message.success("Password reset successful!");
      history.push("/signin");
    } catch (error) {
      setSubmitting(false);
      setError(error.message);
      console.log(error);
      // message.warn(error.message);
    }
  };
  return (
    <div className={classNames.wrapper}>
      <img src={mollyAvatar} alt="Molly logo" className={classNames.logo} />
      <Title as="h1" style={{ fontSize: "36px" }}>
        Reset Password
      </Title>
      {error && (
        <Alert message={error} type="error" style={{ marginBottom: 10 }} />
      )}

      <Form
        form={form}
        layout="vertical"
        hideRequiredMark={true}
        onFinish={emailSubmit ? resetPassword : handleOnFinish}
      >
        {emailSubmit && (
          <Form.Item
            name="code"
            label="Enter the code we emailed you"
            rules={[{ required: true, message: "Code is required" }]}
          >
            <Input
              type="text"
              className={classNames.input}
              onClick={() => {
                setError(false);
                setDisable(false);
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: validation.email.required },
            { type: "email", message: validation.email.valid },
          ]}
        >
          <Input
            // type="email"
            disabled={emailSubmit ? true : false}
            className={classNames.input}
            onClick={() => {
              setDisable(false);
            }}
          />
        </Form.Item>
        {emailSubmit && (
          <Form.Item
            name="password"
            label="New Password"
            rules={[
              { required: true, message: validation.password.required },
              {
                min: 8,
                message: validation.password.minLength,
              },
              {
                pattern: new RegExp(
                  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?\"!@#%&/,><':;|_~`^\\]\\[\\)\\(]).{6,}"
                ),
                message: validation.password.regex,
              },
            ]}
          >
            <Input
              className={classNames.input}
              type="password"
              onClick={() => {
                setDisable(false);
              }}
            />
          </Form.Item>
        )}
        <Spin spinning={submitting}>
          <PrimaryButton
            style={{ width: "100%", fontWeight: "bold" }}
            htmlType="submit"
            type="primary"
            disabled={disable}
          >
            {emailSubmit ? "Change password" : "Request Code"}
          </PrimaryButton>
        </Spin>
      </Form>
      <AuthLinkWrapper>
        <AuthLinkWrapper.Link to="/signup" el={Link}>
          I don't have an account
        </AuthLinkWrapper.Link>

        <BsDot />

        <AuthLinkWrapper.Link to="/signin" el={Link}>
          I have an account
        </AuthLinkWrapper.Link>
      </AuthLinkWrapper>

      <img
        src={mollyText}
        alt="Molly logo"
        className={classNames.mollyLogoText}
      />
    </div>
  );
}

export default ForgotPassword;
