import classNames from "./Signin.module.scss";
import mollyAvatar from "../../../assets/molly-avatar.svg";
import mollyText from "../../../assets/molly-text.svg";
import Drift from "react-driftjs";
import ac from "../../../redux/actions";
import { Form, Spin, Alert, message } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AuthLinkWrapper } from "../../common";
import { BsDot } from "react-icons/bs";
import { validation } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Title, PrimaryButton, Input } from "../../common";

function Signin(props) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const [resetted, setResetted] = useState(localStorage.getItem("reset"));

  const [disable, setDisable] = useState(false);

  const { from } = location.state || { from: { pathname: "/" } };

  const { message: errorMessage } = useSelector(function ({
    signin: { error },
  }) {
    return error ? error : {};
  });

  const fetching = useSelector(function ({ signin: { fetching } }) {
    return fetching;
  });

  const { username } = useSelector(function ({ signin: { data } }) {
    return data ? data : {};
  });

  function onFinish({ email, password }) {
    dispatch(ac.userSignin({ username: email, password }));
  }

  useEffect(() => {
    if (resetted) {
      message.success("Password reset successful!");
      localStorage.clear();
      setResetted(false);
    }
  }, [resetted]);

  useEffect(
    function () {
      if (!!username) {
        // Bye
        history.replace(from);
      }
    },
    [username, history, from]
  );

  const formProps = {
    onFinish: onFinish,
    form: form,
    layout: "vertical",
    hideRequiredMark: true,
    className: classNames.form,
  };

  const emailFieldProps = {
    label: "Email",
    name: "email",
    type: "email",
    rules: [
      { required: true, message: validation.email.required },
      { type: "email", message: validation.email.validity },
    ],
  };

  const passwordFieldProps = {
    label: "Password",
    name: "password",
    rules: [{ required: true, message: validation.password }],
    className: classNames.field,
  };

  return (
    <div className={classNames.wrapper}>
      <img src={mollyAvatar} alt="Molly logo" className={classNames.logo} />
      <Title as="h1" style={{ fontSize: "36px" }}>
        Log In
      </Title>

      <div style={{ marginBottom: 10, width: 400 }}>
        {errorMessage && <Alert type="error" message={errorMessage} />}
      </div>

      <Form
        {...formProps}
        onValuesChange={(changedValues, { email, password }) => {
          if (changedValues && email?.length > 0 && password?.length > 0) {
            setDisable(false);
          } else if (
            (changedValues && email?.length === 0) ||
            password?.length === 0
          ) {
            setDisable(true);
          }
        }}
      >
        <Form.Item {...emailFieldProps}>
          <Input placeholder="" className={classNames.input} />
        </Form.Item>

        <Form.Item {...passwordFieldProps}>
          <Input placeholder="" className={classNames.input} type="password" />
        </Form.Item>

        <Spin spinning={false}>
          <PrimaryButton
            htmlType="submit"
            type="primary"
            style={{ width: "100%", fontWeight: "bold" }}
            loading={fetching}
            disabled={disable}
          >
            Log In
          </PrimaryButton>
        </Spin>
      </Form>

      <AuthLinkWrapper>
        <AuthLinkWrapper.Link to="/signup" el={Link}>
          I don't have an account
        </AuthLinkWrapper.Link>

        <BsDot />

        <AuthLinkWrapper.Link to="/forgot-password" el={Link}>
          Forgot password
        </AuthLinkWrapper.Link>
      </AuthLinkWrapper>

      <img
        src={mollyText}
        alt="Molly logo"
        className={classNames.mollyLogoText}
      />
      <Drift appId="txw66twatn49" />
    </div>
  );
}

export default Signin;
