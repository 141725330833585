import React from "react";
import { FiUsers, FiCalendar, FiSettings, FiClipboard } from "react-icons/fi";

export const bottomSectionItems = [
  {
    order: 1,
    text: "Candidates",
    icon: () => <FiUsers size={18} />,
    to: "candidates",
  },
  {
    order: 2,
    text: "Calendar",
    icon: () => <FiCalendar size={18} />,
    to: "calendar",
  },
  {
    order: 3,
    text: "Settings",
    icon: () => <FiSettings size={18} />,
    to: "settings",
  },

  {
    order: 4,
    text: "Test Library",
    icon: () => <FiClipboard size={18} />,
    to: "test-lib",
  },
];
