const { useState, useEffect } = require("react");

// function getOnlineStatus() {
//   return typeof navigator !== "undefined" &&
//     typeof navigator.onLine === "boolean"
//     ? navigator.onLine
//     : true;
// }

function useOnlineStatus() {
  const [onlineStatus, setOnlineStatus] = useState("initial");

  const goOnline = () => setOnlineStatus(true);

  const goOffline = () => setOnlineStatus(false);

  useEffect(() => {
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  return onlineStatus;
}

export default useOnlineStatus;
