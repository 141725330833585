import { cloneElement } from "react";
import { Children, isValidElement, ReactNode } from "react";
import classNames from "./List.module.scss";
interface Props {
  className?: string;
  children: ReactNode;
}
const List = ({ children }: Props): JSX.Element => {
  return (
    <ul className={classNames.list}>
      <ListItem children={children} />
    </ul>
  );
};
type PropsItem = {
  children: ReactNode;
};

const ListItem = ({ children }: PropsItem): JSX.Element => {
  const childrenArray: any = Children.toArray(children);

  const iconElement =
    childrenArray.length > 0 &&
    childrenArray.forEach((child: any) =>
      child.props.children.filter((item: any) => item.type === "icon")
    );

  const spanElement =
    childrenArray.length > 0 &&
    childrenArray.forEach((child: any) =>
      child.props.children.filter((item: any) => item.type === "span")
    );

  const alterdIcons = Children.map<React.ReactNode, React.ReactNode>(
    iconElement,
    (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          className: child.props.className + " " + classNames.icon,
        });
      }
    }
  );

  const alterdSpans = Children.map<React.ReactNode, React.ReactNode>(
    spanElement,
    (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          className: child.props.className + " " + classNames.answerText,
        });
      }
    }
  );

  //find text or icon
  return iconElement &&
    iconElement.length > 0 &&
    spanElement &&
    spanElement.length > 0 ? (
    <li className={classNames.item}>
      {alterdIcons}
      {alterdSpans}
    </li>
  ) : (
    <li>{children}</li>
  );
};
export default List;
