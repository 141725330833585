const endpoints = {
  ANSWERS: {
    dev: "https://r3chcv8sbg.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://zv2cww9hh3.execute-api.us-west-2.amazonaws.com/prod",
  },
  CANDIDATE_TEST: {
    dev: "https://ifs3uiknw2.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://l7ikbrbow8.execute-api.us-west-2.amazonaws.com/prod",
  },
  DESIGNATION: {
    dev: "https://3dasgerbel.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://uu27zqpqv1.execute-api.us-west-2.amazonaws.com/prod",
  },
  DIFFICULTY: {
    dev: "https://h2fuztvbse.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://zr0occpd86.execute-api.us-west-2.amazonaws.com/prod",
  },
  INDUSTRY: {
    dev: "https://a1ckh8c128.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://3u062fyckb.execute-api.us-west-2.amazonaws.com/prod",
  },
  MESSAGING: {
    SEND: {
      dev: "https://i3lcbyqz7c.execute-api.us-west-2.amazonaws.com/prod/",
      qa: "https://uq4yqglzd4.execute-api.us-west-2.amazonaws.com/prod/",
    },
  },
  QUESTION: {
    dev: "https://7wpq2gx8x9.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://8qtsgurkpd.execute-api.us-west-2.amazonaws.com/prod",
  },
  QUESTION_TYPE: {
    dev: "https://430qy5hxwe.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://pfjw1q9rod.execute-api.us-west-2.amazonaws.com/prod",
  },
  SKILL_TEST: {
    dev: "https://d85sgp2ipk.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://0pzynf2xf6.execute-api.us-west-2.amazonaws.com/prod",
  },
  TEMP_DATA: {
    dev: "https://md4dq04crd.execute-api.us-west-2.amazonaws.com/prod/",
    qa: "https://8c73lbiz28.execute-api.us-west-2.amazonaws.com/prod/",
  },
};

export default endpoints;
