import React from "react";
import Wrapper from "../StrictWrapper/Wrapper";
import classNames from "./SearchBar.module.scss";
import { AutoComplete } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoMdSearch } from "react-icons/io";

const Option = AutoComplete.Option;

const Placeholder = () => (
  <span className={classNames.searchPlaceholder}>
    <IoMdSearch size={22} /> Search for Candidates
  </span>
);

function SearchBar() {
  const data = useSelector(({ getCandidatesByCompanyId }) => {
    return getCandidatesByCompanyId;
  });

  const candidatesList = data ? data.data : null;
  return (
    <Wrapper classes={`${classNames.wrapper} molly__search-header`}>
      <AutoComplete
        className={classNames.searchDropdown}
        placeholder={<Placeholder />}
        optionFilterProp="value"
        filterOption={(input, option) =>
          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {candidatesList
          ? candidatesList.items.map((item) => (
              <Option
                key={item.id}
                value={`${item.firstName} ${item.lastName}`}
              >
                <Link
                  to={`/candidate/${item.id}/${item.jobId}`}
                  style={{
                    width: "100%",
                    display: "inline-block",
                    color: "#000",
                  }}
                >{`${item.firstName} ${item.lastName}`}</Link>
              </Option>
            ))
          : null}
      </AutoComplete>
    </Wrapper>
  );
}

export default SearchBar;
