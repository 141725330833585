import api from "../../../api";
import types from "../../types";
import { generateSyncAction } from "../../utils.redux";

export default function deleteFeedback(input) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.feedback.deleteFeedback.started));

    try {
      const data = await api.feedback.delete(input);
      dispatch(generateSyncAction(types.feedback.deleteFeedback.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.feedback.deleteFeedback.failed, error));
    }
  };
}
