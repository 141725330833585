import React from "react";
import Card from "../Card";
// import findChildByTypeName from "../utils/findChildByTypeName";
import Heading from "./Heading";
import Body from "./Body";
import Footer from "./Footer";

type Props = {
  children?: React.ReactNode | null;
  style?: React.CSSProperties;
  className?: string;
};

const QuestionType = ({
  children,
  style,
  className,
}: Props): React.ReactElement | null => {
  // const Heading = findChildByTypeName(children, "Heading");
  // const Body = findChildByTypeName(children, "Body");
  // const Footer = findChildByTypeName(children, "Footer");

  // const Other = Children.toArray(children).filter(
  //   (Child): ReactNode => {
  //     if (isValidElement(Child) && typeof Child.type === "function") {
  //       return Child.type.name !== "Heading";
  //     } else if (isValidElement(Child)) {
  //       return Child;
  //     }

  //     return null;
  //   }
  // );

  console.log(Heading, Body, Footer, "<><><>");

  return (
    <Card
      shadow="softer"
      variant="medium"
      noBorder
      style={style}
      className={className}
    >
      {children}
    </Card>
  );
};

QuestionType.Heading = Heading;
QuestionType.Body = Body;
QuestionType.Footer = Footer;

export default QuestionType;
