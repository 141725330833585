import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";

/**
 * This function configures redux store.
 */
export function configStore(
  reducers = function () {
    return {};
  }
) {
  const composeEnhancers =
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    process.env.NODE_ENV === "development"
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  const logger = createLogger();
  let middleware = [thunk];

  if (process.env.NODE_ENV === "development") {
    middleware = [
      ...middleware,
      logger,
      // require("redux-immutable-state-invariant").default(),
    ];
  }

  const enhancer = composeEnhancers(
    applyMiddleware(...middleware)
    // other store enhancers if any
  );

  return createStore(reducers, {}, enhancer);
}

export function generateActionTypes(namespace) {
  return {
    started: `@${namespace}/started`,
    success: `@${namespace}/success`,
    failed: `@${namespace}/failed`,
  };
}

export function generateSyncAction(type, data) {
  return {
    type,
    data,
  };
}
