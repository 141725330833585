import types from "../../../types";
import { QUESTION_TABLE_NAME } from "../../../tables";

export default function listQuestionsByIds(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.question.listQuestionsByIds.started:
      return Object.assign({}, { fetching: true });

    case types.question.listQuestionsByIds.success:
      console.log(
        "action.data.result.Responses[QUESTION_TABLE_NAME]",
        action.data
      );
      return Object.assign(
        {},
        {
          fetching: false,
          success: true,
          data:
            action.data.result.Responses[
              QUESTION_TABLE_NAME[process.env.REACT_APP_ENV]
            ],
        }
      );

    case types.tempAnswers.mapAnswersWithQuestion.success:
      let copy = [...state.data];
      let ques = copy.find((value) => value.id === action.data.questionId);
      ques.answer = [...action.data.option];
      return Object.assign({}, { fetching: false, data: [...copy] });

    case types.question.listQuestionsByIds.failed:
      return Object.assign({}, { fetching: false, success: false });

    default:
      return state;
  }
}
